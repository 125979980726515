import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "@/lib/utils"

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-lg text-base transition-colors focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-ring disabled:pointer-events-none disabled:bg-secondary-500 border hover:text-primary hover:bg-red-100",
  {
    variants: {
      variant: {
        default:
          "bg-secondary text-white border-none shadow hover:bg-secondary-950 hover:text-white focus:bg-secondary-950 focus:text-white disabled:bg-secondary-300",
        primarybtn:
          "bg-primary text-white border-none shadow hover:bg-primary-700 hover:text-white focus:bg-primary-700 focus:text-white disabled:bg-primary-300",
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-destructive/90 hover:text-light",
        subscribebtn:
          "bg-secondary text-base text-white border-secondary-400 hover:bg-secondary-950 hover:text-white focus:bg-secondary-950 focus:text-white disabled:bg-secondary-300",
        outline:
          "border border-1 border-secondary-400 text-secondary hover:bg-secondary-100 hover:border-secondary-300 hover:text-secondary-950 focus:bg-secondary-100 focus:text-secondary-950",
        primaryoutline:
          "border border-1 border-primary-500 text-primary-500 hover:bg-primary-50 hover:border-primary-700 focus:bg-primary-50 focus:border-primary-700",
        secondary:
          "border-none bg-secondary text-white shadow-sm hover:bg-secondary-950 focus:bg-secondary-950 hover:text-white focus:text-white disabled:bg-secondary-700",
        ghost:
          "hover:bg-secondary-100 hover:text-secondary-950 focus:bg-secondary-100 focus:text-secondary-950",
        link: "text-primary underline-offset-4 hover:underline",
        social_media_button:
          "border border-input bg-white text-secondary hover:bg-secondary-950 hover:text-white",
        social_media_button_selected:
          "bg-secondary text-white hover:bg-secondary-950 hover:text-white",
      },
      size: {
        medium: "h-11 px-5 py-2 border leading-6",
        sm: "h-9 rounded-md px-3 text-xs",
        lg: "h-10 rounded-md px-8",
        icon: "h-9 w-9",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "medium",
    },
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button"
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    )
  }
)
Button.displayName = "Button"

export { Button, buttonVariants }
