"use client"

import { format } from "date-fns"

import { ProductData } from "@/app/types/product"

interface Price {
  id: string
  stripe_price_id: string
  active: boolean
  currency: string
  unit_amount: number
  type: string
  recurring?: {
    interval: string
  }
  metadata: {
    name: string
    description: string
    level?: string
  }
}

interface SubscriptionDetails {
  subscription_renew_date: string | Date
  cancel_at?: string | null
  stripe_price_id: string
  subscription_status?: string
}

interface ActivePlanContentProps {
  product: ProductData
  subscriptionDetails?: SubscriptionDetails
}

export function ActivePlanContent({
  product,
  subscriptionDetails,
}: ActivePlanContentProps) {
  const activePrice = product.prices?.find(
    (p) => p.stripe_price_id === subscriptionDetails?.stripe_price_id
  )

  return (
    <>
      <h3 className="mb-2 text-lg font-semibold leading-7 text-white">
        {activePrice?.metadata?.name || "Current Plan"}
      </h3>
      <p className="text-base leading-7">
        {activePrice?.metadata?.description || "Your current subscription plan"}
      </p>
      {subscriptionDetails && (
        <p
          className={`mt-3 text-sm font-semibold leading-5 ${
            subscriptionDetails.cancel_at
              ? "text-red-400"
              : subscriptionDetails.subscription_status === "past_due"
                ? "text-red-400"
                : "text-secondary-700"
          }`}
        >
          {subscriptionDetails.cancel_at
            ? "Subscription ends on: "
            : subscriptionDetails.subscription_status === "past_due"
              ? "Due date: "
              : "Renew on: "}
          {format(subscriptionDetails.subscription_renew_date, "MM/dd/yyyy")}
        </p>
      )}
    </>
  )
}
